<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="justify-content-end">
            <b-col cols="6">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input placeholder="Search" v-model="search" />
              </b-input-group>
            </b-col>
            <b-col cols="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="w-100"
                @click="
                  () => {
                    $router.push('/users/role-form/new');
                  }
                "
              >
                Add Role
              </b-button>
            </b-col>
          </b-row>

          <div class="box mt-2">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="RoleLists"
              :fields="fields"
            >
              <template #cell(role_is_active)="data">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  :checked="data.item.role_is_active"
                  @change="activateRole(data.item)"
                >
                </b-form-checkbox>
              </template>
              <template #cell(edit)="data">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    () => {
                      $router.push(`/users/role-form/${data.item.role_id}`);
                    }
                  "
                />
              </template>
            </b-table>
          </div>

          <b-card class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="allRoleLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserServices from "@/apiServices/UserServices";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      fields: [
        {
          key: "role_key",
          label: "Role Key",
          sortable: true,
        },
        {
          key: "role_name",
          label: "Role Name",
          sortable: true,
        },
        {
          key: "role_is_active",
          label: "Status",
        },
        {
          key: "edit",
          label: "Edit",
        },
      ],
      allRoleLists: [],
    };
  },
  computed: {
    RoleLists: function () {
      let arr = this.allRoleLists;
      let search = this.search;
      if (search) {
        search = search.toLowerCase();
        arr = arr.filter((role) => {
          let role_name = role.role_name.toLowerCase();
          if (
            (role_name && role_name.includes(search))
          ) {
            return true;
          }
          return false;
        });
      }
      return arr;
    },
  },
  methods: {
    getAllRoles() {
      UserServices.getAllRole()
        .then((response) => {
          if (response.data.status) {
            this.allRoleLists = response.data.data;
            // console.log(this.allRoleLists);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Role Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
    activateRole(role) {
      let paylod = {
        role_id: role.role_id,
        status: role.role_is_active ? false : true,
      };
      UserServices.setRoleStatus(paylod)
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Role Status is Updated SuccessFully.",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getAllRoles();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Role Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllRoles();
  },
};
</script>
